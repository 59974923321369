function StartTime(props) {
    const {setStartTime,startTime} = props;
    return (
    <>
        <span>Earliest Time for anyone:</span>
        <input type="time" value={startTime} onChange={(event)=>{setStartTime(event.target.value)}}></input>
        <br></br>
    </>
    )
}

export default StartTime;