import YourTZ from "./your-tz";
import IncludeTZs from "./include-tzs";
import StartTime from "./start-time";
import EndTime from "./end-time";
import { useEffect, useState } from "react";

function TimezoneTool() {
    const [timezones,setTimezones] = useState([]);
    const [yourTimezone,setYourTimezone] = useState("Australia/Sydney");
    const [includeTimezones,setIncludeTimezones] = useState([]);
    const [startTime,setStartTime] = useState("08:00");
    const [endTime,setEndTime] = useState("18:00");
    const [timezoneDetails,setTimezoneDetails] = useState({});
    const [intervals,setIntervals] = useState([]);

    
    useEffect(()=>{
        fetch('/api/TimeZone/AvailableTimeZones').then(
                (resp)=>resp.json()
            ).then(
                (json)=>{
                    //console.log(json[10]);
                    setTimezones(json);
                }
            );
    },[]);
    
    useEffect(()=>{
        if (yourTimezone !== ""){
            setIncludeTimezones(
                includeTimezones.indexOf(yourTimezone) === -1 ? includeTimezones.concat([yourTimezone]) : includeTimezones
            );
        }
    },[yourTimezone]);

    useEffect(()=>{
        for (const value of includeTimezones) {
            fetch("/api/TimeZone/zone?timeZone="+value).then(
                (resp)=>resp.json()
            ).then(
                (json)=>{
                    setTimezoneDetails({
                        ...timezoneDetails,
                        [value] : {
                            offset : -(json["currentUtcOffset"]["seconds"]+24*60*60) % (24*60*60),
                            startTime : (parseInt(startTime.split(":")[0])*60*60 + parseInt(startTime.split(":")[1])*60 - json["currentUtcOffset"]["seconds"] + 60*60*24) % (60*60*24),
                            endTime : (parseInt(endTime.split(":")[0])*60*60 + parseInt(endTime.split(":")[1])*60 - json["currentUtcOffset"]["seconds"] + 60*60*24) % (60*60*24)
                        }
                    })
                }
            );
        }
    },[includeTimezones,startTime,endTime,yourTimezone]);
    
    function intersection(a,b){
        if (b.start > a.end || a.start > b.end)
            return undefined
        return [{
            start : Math.max(a.start,b.start),
            end : Math.min(a.end,b.end)
        }]
    }

    useEffect(()=>{
        let first = true;
        let new_intervals = [];
        for (const tz of includeTimezones){
            if (timezoneDetails[tz] === undefined) {
                return
            }
            const next_intervals = []
            if (timezoneDetails[tz]["startTime"] < timezoneDetails[tz]["endTime"]) {
                next_intervals.push({
                    start : timezoneDetails[tz]["startTime"],
                    end : timezoneDetails[tz]["endTime"]
                });
            } else {
                next_intervals.push({
                    start : 0,
                    end : timezoneDetails[tz]["endTime"]
                });
                next_intervals.push({
                    start : timezoneDetails[tz]["startTime"],
                    end : 60*60*24
                });
            }
            if (first) {
                new_intervals = next_intervals;
                first = false;
                continue
            }
            // now we have a list of valid existing intervals
            // we need to pass them each with the proposed new
            // intervals and return the list of compatible new 
            // intervals for the next iteration
            const updated_intervals = []
            for (const interval of new_intervals){
                for (const next_interval of next_intervals){
                    const intersect = intersection(interval,next_interval);
                    if (intersect !== undefined) {
                        updated_intervals.push(...intersect);
                    }
                }    
            }
            new_intervals = updated_intervals;
        }
        setIntervals(new_intervals);
    },[timezoneDetails]);

    const [localIntervals,setLocalIntervals] = useState([]);
    useEffect(()=>{
        if (timezoneDetails[yourTimezone] === undefined) return;
        const locInts = [];
        for (const interval of intervals) {
            console.log(interval)
            console.log(timezoneDetails[yourTimezone].offset/60/60)
            const newInt = {
                start : ((interval.start-timezoneDetails[yourTimezone].offset + 24*60*60) % (24*60*60)),
                end : ((interval.end-timezoneDetails[yourTimezone].offset + 24*60*60) % (24*60*60))
            }
            if (newInt.start === newInt.end) continue;
            let absorbed = false;
            for (const locInt of locInts) {
                console.log(locInt,newInt)
                if (newInt.start === newInt.end) {
                    absorbed = true;
                    break
                }
                if (locInt.start === newInt.end){
                    locInt.start = newInt.start;
                    absorbed = true;
                    break
                }
                if (locInt.end === newInt.start){
                    locInt.end = newInt.end;
                    absorbed = true;
                    break
                }
            }
            if (absorbed) {
                continue
            }
            locInts.push(newInt);
        }
        setLocalIntervals(locInts);
    },[yourTimezone,intervals])

    function formatTime(time) {
        return `${Math.floor(time/3600).toFixed(0).padStart(2,"0")}:${((time/60)%60).toFixed(0).padStart(2,"0")}`
    }

    return ( 
    <>
        <YourTZ timezones={timezones} setYourTimezone={setYourTimezone} yourTimezone={yourTimezone}/>
        <IncludeTZs timezones={timezones} includeTimezones={includeTimezones} setIncludeTimezones={setIncludeTimezones}/>
        <StartTime setStartTime={setStartTime} startTime={startTime}/>
        <EndTime setEndTime={setEndTime} endTime={endTime}/>
        Valid meeting times ({yourTimezone} time):
        <ul>
            {
                localIntervals.map((value,index) => {
                    return (
                    <li key={index}>
                        {formatTime(value.start)} to {formatTime(value.end)}
                    </li>
                    )
                })
            }
        </ul>
        </>
    );
}
export default TimezoneTool;