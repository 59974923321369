function EndTime(props) {
    const {setEndTime,endTime} = props;
    return (
    <>
        <span>Latest time for anyone:</span>
        <input type="time" value={endTime} onChange={(event)=>setEndTime(event.target.value)}></input>
        <br></br>
    </>
    )
}

export default EndTime;