function TimezoneSelector (props) {
    const {timezones,setTimezone,list,name} = props;
    function timezoneChanged(event) {
        const val = event.target.value;
        if (timezones.includes(val)){
            setTimezone(val);
            event.target.value = "";
        }
    }
    return (
    <>
        <input onChange={timezoneChanged} list={list} id={name} name={name}/>
        <datalist id={list}>
            {timezones.map((value,index)=>{
                return (
                    <option key={index} value={value}></option>
                )
            })}
        </datalist>
    </>
    )
}

export default TimezoneSelector;