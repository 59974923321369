import { useEffect, useState } from "react";
import TimezoneSelector from "./timezone-selector";

function IncludeTZs(props) {
    const {timezones,includeTimezones,setIncludeTimezones} = props;
    const [newTZ,setNewTZ] = useState("");
    useEffect(()=>{
        if (timezones.includes(newTZ)) {
            setIncludeTimezones(
                includeTimezones.indexOf(newTZ) === -1 ? includeTimezones.concat([newTZ]) : includeTimezones
            );
            setNewTZ("");
        }
    },[newTZ]);
    function removeTimezone(name) {
        if (includeTimezones.includes(name)) {
            setIncludeTimezones(
                includeTimezones.filter((item)=>{
                    return item !== name
                })
            )
        }
    }
    return (
    <>
        <span>Include timezones:</span>
        <TimezoneSelector timezones={timezones} setTimezone={setNewTZ} list="your-timezone-list" name="your-timezone"/>
        <table>
            <tbody>
            {includeTimezones.map((value,index)=>{
                if (value !== ""){
                    return (
                        <tr key={index}>
                            <td>{value}</td><td style={{"fontSize":"10pt"}} onClick={()=>removeTimezone(value)}>❌</td>
                        </tr>
                    )
                }
            })}
            </tbody>
        </table>
        <br></br>
    </>
    )
}

export default IncludeTZs;