import TimezoneSelector from "./timezone-selector";

function YourTZ(props) {
    const {timezones,setYourTimezone,yourTimezone} = props;
    return (
    <>
        <span>Your timezone:</span>
        <TimezoneSelector timezones={timezones} setTimezone={setYourTimezone} list="your-timezone-list" name="your-timezone"/>
        <div>{yourTimezone}</div>
        <br></br>
    </>
    )
}

export default YourTZ;